








import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockableBlockColorFragment, blockColorMixin } from '@/composables/block-color';
import { useActivePerformances } from '@/composables/performances';

import { ScenkonstOnStageBlockDataFragment } from '@/graphql/generated';
import { ComposableMixin } from '@/mixins/composable';
import { PageBlockMixin } from '@/modules/cms';

@Component({
  graphqlData: gql`
    fragment ScenkonstOnStageBlockData on ScenkonstOnStageBlock {
      id
      title
      ...BlockColor
    }

    ${blockableBlockColorFragment}
  `,
})
export default class ScenkonstOnStageBlock extends mixins(
  PageBlockMixin<ScenkonstOnStageBlockDataFragment>(),
  blockColorMixin,
  ComposableMixin(useActivePerformances, () => [{ limit: 6 }]),
) {}

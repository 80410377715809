import { computed, ref } from '@nuxtjs/composition-api';
import { useQuery } from '@vue/apollo-composable';

import { usePaginatedQuery } from './pagination';

import {
  ActivePerformancesQuery,
  ActivePerformancesQueryVariables,
  ArchivedPerformancesQuery,
  ArchivedPerformancesQueryVariables,
} from '@/graphql/generated';
import { activePerformancesQuery, archivedPerformancesQuery } from '@/graphql/queries/performances';

export type ActivePerformance = ActivePerformancesQuery['scenkonstOtPerformances'][0]

export const useActivePerformances = (options?: ActivePerformancesQueryVariables) => {
  const { result, loading } = useQuery<ActivePerformancesQuery, ActivePerformancesQueryVariables>(
    activePerformancesQuery,
    { ...options },
  );

  return {
    performances: computed(() => result.value?.scenkonstOtPerformances || []),
    performancesLoading: loading,
  };
};

export type ArchivedPerformance = ArchivedPerformancesQuery['scenkonstOtPerformances'][0]

export const useArchivedPerformances = () => {
  const seasons = ref<string[]>([]);
  const limit = 6;

  const { result, loading, fetchMore, queryKey } = usePaginatedQuery<ArchivedPerformancesQuery, ArchivedPerformancesQueryVariables>(
    archivedPerformancesQuery,
    () => ({
      seasons: [...seasons.value],
      limit,
    }),
  );

  return {
    seasons,
    performances: computed(() => result.value?.scenkonstOtPerformances || []),
    performancesLoading: loading,
    performancesQueryKey: queryKey,
    fetchMorePerformances: fetchMore,
  };
};
